import React, { Component } from 'react';
import Card from '../shared/Card';
import {Button} from "primereact/components/button/Button";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import TextSemiBold from "../shared/Typography/TextSemiBold";
import {Chart} from "primereact/components/chart/Chart";
import {formatCurrency, formatNumber} from "../utils/commonFunctions";
import {Calendar} from "primereact/calendar";
import {CarService} from "../../service/CarService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Growl} from "primereact/growl";
import {ProgressSpinner} from "primereact/progressspinner";
import {SelectButton} from "primereact/selectbutton";
import classNames from "classnames";
import {TabPanel, TabView} from "primereact/tabview";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {MapplicWrapper} from "../Wrapper/MapplicWrapper";

class DashboardAirtm extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state = {
            data: new FormData(),
            fileName: "",
            merchantId: "",
            listMechants: [],
            listAddress: [],
            rates: [],
            catBanks: [],
            bankId: "",
            balanceTotalFee: 0.0,
            balanceTotalCost: 0.0,
            balanceNetFees: 0.0,
            balanceMswipe: 0.0,
            balanceEwi: 0.0,
            balanceBest: 0.0,
            balanceRecurrente: 0.0,
            balancePayment: 0.0,
            balanceRefund: 0.0,
            balanceWeek: 0.0,
            balanceBiWeek: 0.0,
            balanceMonthly: 0.0,
            header: {
                temple: 100.95,
                gifts: 250.25,
                causes_helps: 650.56,
                recurring: 1740.26
            },
            donation_date: '14 de octubre del 2020, 10:45 PM',
            donation_period: null,
            totalBox1: {
                disponible: 1000,
                por_acreditar: 2000,
                transferido: 3000,
                reserva: 4000,
                registrados: 5000
            },

            dataTableValue: [],
            monthlyDataset: {},
            merchantData: {},
            mapCountryData: {},
            arratCountryData: [],
            lastDonations: [],
            chargedYet: false,
            reportTypeData: {},
            series_history: {},
            series_load_history: {},
            series_history_tier_0: {},
            series_load_history_tier_0: {},
            series_history_tier_1: {},
            series_load_history_tier_1: {},

            info_endbal_tier_gral: {},
            info_dict_tier_gral: {},
            info_dict_tier_0: {},
            info_dict_tier_1: {},
            info_dict_tier_silver: {},

            series_history_tier_silver_virtual: {},
            series_load_history_silver_virtual: {},
            report_detail:[],
            report_detail_cost:[],
            report_creation_fee:[],
            report_load_fee:[],
            report_purchase_fee:[],
            report_unload_fee:[],
            payment_mswipe: {
                cost: 0.0,
                profit: 0.0,
                total: 0.0
            },
            payment_best: {
                cost: 0.0,
                profit: 0.0,
                total: 0.0
            },
            //Airtm Info
            report_airtm_creation_fee:{},
            report_airtm_load_fee:{},
            report_airtm_net_cards:{},
            report_airtm_purchase_fee:{},
            report_airtm_unload_fee:{},
            report_airtm_unload_amount:{},
            report_airtm_load_amt:{},
            report_airtm_purchase_amt:{},

            report_net_card_tier_0:{},
            report_net_card_tier_1:{},
            report_net_card_tier_silvervirtual:{},

            report_airtm_creation_fee_series:{},
            frequency: '1',
            catFrequency: [
                {label: 'weekly', value: '1'},
                {label: 'monthly', value: '2'},
                {label: 'annual', value: '3'}
            ],
            lst_loads_reversal_tier_0:[],
            lst_loads_reversal_tier_1:[],
            lst_feeloads_reversal_tier_0:[],
            lst_feeloads_reversal_tier_1:[],
            map_country_compras:{},
            map_country_activation:{},
            map_compras:null,
            map_activations:null,
            list_country_compras:[],
            list_country_activation:[],
            report_net_fee_card_tier_0:{},
            report_net_fee_card_tier_1:{},
            report_net_fee_card_tier_silver:{},
        }
        this.carService = new CarService();

        this.initCatalogos = this.initCatalogos.bind(this);
        this.getBalanceNode = this.getBalanceNode.bind(this);
        this.getReportSumary = this.getReportSumary.bind(this);
        this.getReportSumaryAirtm = this.getReportSumaryAirtm.bind(this);
        this.getReportGralPay = this.getReportGralPay.bind(this);
        this.getReporHistorySeries = this.getReporHistorySeries.bind(this);
        this.getReportCardActivationSerie = this.getReportCardActivationSerie.bind(this);
        this.getReporLoadFundFeeWallet = this.getReporLoadFundFeeWallet.bind(this);
        this.getReportMaps = this.getReportMaps.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.getBalanceNode();
        this.getReportSumary();
        this.getReportSumaryAirtm();
        this.getReportGralPay();
        this.getReporHistorySeries();
        this.getReporLoadFundFeeWallet();
        this.getReportCardActivationSerie();
        this.getReportMaps();
        this.callReset();
    }

    getBalanceNode(){



    }

    getReportGralPay(){



    }


    getReporLoadFundFeeWallet(){
        this.usuarioService= new UsuarioService();
        let params={}
        if (this.state.donation_period){
            params={month:this.state.donation_period}

        }else{
            let date = new Date().toISOString();
            params={month:date}
            //this.setState({donation_period: date});
        }
        console.log(params)

        this.usuarioService.postReportIssuingFundFeeWallet(params).then(resp => {
            if(resp.status === 'success'){
                const lst_loads_reversal_tier_0 = resp.lst_loads_reversal_tier_0;
                const lst_loads_reversal_tier_1 = resp.lst_loads_reversal_tier_1;

                const lst_feeloads_reversal_tier_0 = resp.lst_fee_loads_reversal_tier_0;
                const lst_feeloads_reversal_tier_1 = resp.lst_fee_loads_reversal_tier_1;

                this.setState({lst_loads_reversal_tier_0});
                this.setState({lst_loads_reversal_tier_1});

                this.setState({lst_feeloads_reversal_tier_0});
                this.setState({lst_feeloads_reversal_tier_1});



            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }


    getReportMaps(donation_period){

        this.usuarioService= new UsuarioService();
        let params={}
        if (donation_period){
            console.log(donation_period)
            params={month:donation_period}

            const map_compras = null;
            const map_activations = null
            this.setState({map_compras});
            this.setState({map_activations});

        }else{
            let date = new Date().toISOString();
            console.log(date)
            params={month:date}
            //this.setState({donation_period: date});
        }
        console.log(params)

        this.usuarioService.postReportIssuingMaps(params).then(resp => {
            if(resp.status === 'success'){

                const map_country_compras= resp.map_country_compras;
                const map_country_activation= resp.map_country_activations;
                const list_country_compras = resp.lst_compras;
                const list_country_activation = resp.lst_activations;
                const map_compras = resp.map_compras;
                const map_activations = resp.map_activations;

                this.setState({map_country_compras});
                this.setState({map_country_activation});
                this.setState({list_country_compras});
                this.setState({list_country_activation});
                this.setState({map_compras});
                this.setState({map_activations});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getReporHistorySeries(){

        this.usuarioService= new UsuarioService();
        let params={}
        if (this.state.donation_period){
            params={month:this.state.donation_period}

        }else{
            let date = new Date().toISOString();
            params={month:date}
            //this.setState({donation_period: date});
        }
        console.log(params)

        this.usuarioService.postReportIssuingHistory(params).then(resp => {
            if(resp.status === 'success'){
                const series_history = resp.data;
                const series_load_history = resp.data_load;

                const series_history_tier_0 = resp.data_0;
                const series_load_history_tier_0 = resp.data_load_0;

                const series_history_tier_1 = resp.data_1;
                const series_load_history_tier_1 = resp.data_load_1;

                const series_history_tier_silver_virtual = resp.data_load_silver_virtual;
                const series_load_history_silver_virtual = resp.data_load_silver_virtual;

                this.setState({series_history});
                this.setState({series_load_history});

                this.setState({series_history_tier_0});
                this.setState({series_load_history_tier_0});

                this.setState({series_history_tier_1});
                this.setState({series_load_history_tier_1});

                this.setState({series_history_tier_silver_virtual});
                this.setState({series_load_history_silver_virtual});

                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getReportCardActivationSerie(){

        this.usuarioService= new UsuarioService();
        this.usuarioService.getReportAirtmCardActivationIssuingSerie().then(resp => {
            if(resp.status === 'success'){

                const report_airtm_creation_fee_series = resp.data;
                this.setState({report_airtm_creation_fee_series});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    getReportSumary(donation_period){

        this.usuarioService= new UsuarioService();
        let params={}
        if (donation_period){
            console.log(donation_period)
            params={month:donation_period}

        }else{
            let date = new Date().toISOString();
            console.log(date)
            params={month:date}
            //this.setState({donation_period: date});
        }
        console.log(params)

        this.usuarioService.postReportIssuingSumary(params).then(resp => {
            if(resp.status === 'success'){


                const report_net_card_tier_0 = resp.data.report_net_card_tier_0;
                const report_net_card_tier_1 = resp.data.report_net_card_tier_1;
                const report_net_card_tier_silvervirtual = resp.data.report_net_card_tier_silvervirtual;

                const info_dict_tier_0 = resp.data.info_dict_tier_0;
                const info_dict_tier_1 = resp.data.info_dict_tier_1;
                const info_dict_tier_gral = resp.data.info_dict_tier_gral;
                const info_endbal_tier_gral = resp.data.info_endbal_tier_gral;

                const info_dict_tier_silver = resp.data.info_dict_tier_silver;

                const report_net_fee_card_tier_0 = resp.data.report_net_fee_card_tier_0;
                const report_net_fee_card_tier_1 = resp.data.report_net_fee_card_tier_1;
                const report_net_fee_card_tier_silver = resp.data.report_net_fee_card_tier_silver;

                this.setState({report_net_fee_card_tier_0});
                this.setState({report_net_fee_card_tier_1});
                this.setState({report_net_fee_card_tier_silver});

                this.setState({info_dict_tier_0});
                this.setState({info_dict_tier_1});
                this.setState({info_dict_tier_gral});
                this.setState({info_endbal_tier_gral});
                this.setState({info_dict_tier_silver});

                this.setState({report_net_card_tier_0});
                this.setState({report_net_card_tier_1});
                this.setState({report_net_card_tier_silvervirtual});

                const report_detail = resp.data.report_detail;
                const report_detail_cost = resp.data.report_detail_cost;
                const report_creation_fee = resp.data.report_creation_fee;
                const report_load_fee = resp.data.report_load_fee;
                const report_purchase_fee = resp.data.report_purchase_fee;
                const report_unload_fee = resp.data.report_unload_fee;

                this.setState({report_detail});
                this.setState({report_detail_cost});




                this.setState({report_creation_fee});
                this.setState({report_load_fee});
                this.setState({report_purchase_fee});
                this.setState({report_unload_fee});


                const mapGrand = resp.data.dict_grand;
                const balanceTotalFee = mapGrand.grand_total;
                const balanceTotalCost = mapGrand.grand_total_cost;
                const balanceNetFees = mapGrand.grand_net_fee;
                const balanceMswipe = mapGrand.grand_mswipe;
                const balanceEwi = mapGrand.grand_ewi;
                const balanceBest = mapGrand.grand_best;

                this.setState({balanceTotalFee});
                this.setState({balanceTotalCost});
                this.setState({balanceNetFees});
                this.setState({balanceMswipe});
                this.setState({balanceEwi});
                this.setState({balanceBest});




                const payment_mswipe = resp.data.dict_mswipe_pay;
                const payment_best = resp.data.dict_best_pay;
                this.state.payment_mswipe.cost = payment_mswipe.total_cost;
                this.state.payment_mswipe.profit = payment_mswipe.mswipe;
                this.state.payment_mswipe.total = payment_mswipe.total;

                this.state.payment_best.cost = payment_best.total_cost;
                this.state.payment_best.profit = payment_best.best;
                this.state.payment_best.total = payment_best.total;


                this.setState({chargedYet: true});


                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getReportSumaryAirtm(donation_period){

        this.usuarioService= new UsuarioService();
        let params={}
        if (donation_period){
            params={month:donation_period}

        }else{
            let date = new Date().toISOString();
            params={month:date}
            //this.setState({donation_period: date});
        }
        console.log(params)

        this.usuarioService.postReportAirtmIssuingSumary(params).then(resp => {
            if(resp.status === 'success'){
                const mapGrand = resp.data.dict_grand;

                const report_airtm_creation_fee = resp.data.report_creation_fee;
                const report_airtm_load_fee = resp.data.report_load_fee;
                const report_airtm_net_cards = resp.data.report_net_cards;

                const report_airtm_purchase_fee = resp.data.report_purchase_fee;
                const report_airtm_unload_fee = resp.data.report_unload_fee;
                const report_airtm_unload_amount = resp.data.report_unload_amount_fee;
                const report_airtm_load_amt = resp.data.report_load_amt;
                const report_airtm_purchase_amt = resp.data.report_purchase_amt;


                this.setState({report_airtm_creation_fee});
                this.setState({report_airtm_load_fee});
                this.setState({report_airtm_net_cards});
                this.setState({report_airtm_purchase_fee});
                this.setState({report_airtm_unload_fee});
                this.setState({report_airtm_unload_amount});
                this.setState({report_airtm_load_amt});
                this.setState({report_airtm_purchase_amt});


                this.setState({chargedYet: true});


                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    initCatalogos(){


    }

    callSearch = (periodo) => {
        console.log('callSearch');
        let donation_period=periodo;
        this.setState({chargedYet: false});
        this.setState({donation_period})
        this.getReportSumary(donation_period)
        this.getReportSumaryAirtm(donation_period)
        this.getReportMaps(donation_period)
    }

    callExportExcel= e => {

        this.usuarioService= new UsuarioService();
        let params={}
        if (this.state.donation_period){
            params={month:this.state.donation_period}

        }else{
            let date = new Date().toISOString();
            params={month:date}
            //this.setState({donation_period: date});
        }
        console.log(params)
        this.usuarioService.postExportReportIssuingSumary(params).then(resp => {
            //var file =new File([resp], "data.xls", { type: 'application/ms-excel' })
            //var file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
            //var blob =  new Blob(resp, {type: "application/ms-excel"});
            //FileSaver.saveAs(blob);
            const downloadUrl = window.URL.createObjectURL(resp);

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'ReportCadIssuing.xls'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();

        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    callReset = () => {

    }

    render() {

        const handleClick = (e, countryCode) => {
            console.log(countryCode);
        };

        const { savingViewMore, map_compras,map_activations} = this.state;

        // weekly Saving Data
        const weeklySavingData = {
            labels: ['Monthly','Bi Weekly','Weekly'],
            datasets: [
                {
                    data: [this.state.balanceMonthly, this.state.balanceBiWeek,this.state.balanceWeek],
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56"
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56"
                    ]
                }
            ]
        };

        // Monthly Bas Chart Data
        const monthlySavingData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
                'August', 'September','October', 'November', 'December'],
            datasets: [
                {
                    label: 'Received',
                    backgroundColor: '#42A5F5',
                    data: [65, 59, 80, 81, 56, 55, 40, 80, 81, 56, 55, 40]
                },
                {
                    label: 'Payment',
                    backgroundColor: '#9CCC65',
                    data: [28, 48, 40, 19, 86, 27, 90, 40, 19, 86, 27, 90]
                }
            ]
        };

        const mapCountry= {"US":33.0,"MX":2977.36,"GB":120.0,"HN":7.18};
        const mapCountryList= [{"country_code":"US","total_amount":33.0},{"country_code":"MX","total_amount":2977.36},{"country_code":"GB","total_amount":120.0},{"country_code":"HN","total_amount":7.18}];

        const selectorPeriod = (
            <div className="p-grid p-fluid mt-5 mb-10">
                <div className="p-col-3">
                    <Calendar readOnly={true} showIcon={true} dateFormat="MM/yy" placeholder="Working Month"
                              view="month" value={this.state.donation_period}
                              onChange={(e) => this.callSearch(e.value)}/>
                </div>

                {/*
                <div className="p-col-2">
                    <Button label="Reset" onClick={this.callReset}/>
                </div>
                <div className="p-col-3">
                    <SelectButton value={this.state.frequency}
                                  options={this.state.catFrequency}
                                  onChange={event => this.setState({frequency: event.value})}/>
                </div>
                */}
                {this.state.chargedYet ? '' :
                    <div className="p-col-12 ta-right" style={{padding: 0}}>
                        <ProgressSpinner style={{
                            width: '50px',
                            height: '50px',
                            position: "absolute",
                            margin: "-50px 0 0 -60px"
                        }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                    </div>
                }
            </div>
        );

        const header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="Excel" onClick={this.callExportExcel}></Button></div>;
        const lightOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };

        const options = {
            animation: false,
            scaleLabel:
                function(label){return  '$' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}
        };

        const stockBodyTemplateAmount = (rowData,column) => {
            const stockClassName = classNames({
                'lowstock': rowData[column.field].value <= 0,
                'instock': rowData[column.field].value > 0
            });

            return (
                <div className={stockClassName}>
                    {formatCurrency(rowData[column.field].value)}
                </div>
            );
        }

        const stockBodyTemplateCount = (rowData,column) => {
            const stockClassName = classNames({
                'lowstock': rowData[column.field].value <= 0,
                'instock': rowData[column.field].value > 0
            });

            return (
                <div className={stockClassName}>
                    {rowData[column.field].value}
                </div>
            );
        }

        const stockBodyTemplateAmount2 = (rowData) => {
            const stockClassName = classNames({
                'lowstock': rowData <= 0,
                'instock': rowData > 0
            });

            return (
                <div className={stockClassName}>
                    {formatCurrency(rowData)}
                </div>
            );
        }

        return(
            <div className="pageWrapper" >
                <Growl ref={(el) => this.growl = el} />
                {selectorPeriod}
                <div className="p-grid">

                    <Card height="height-100">
                        <TabView>
                            <TabPanel header="General">

                                {/* Headers Ending Balance*/}
                                <div className="p-grid dashboard">
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>Ending Balance Silver</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_endbal_tier_gral.end_balance_tier_silvervirtual)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Ending Balance Tier 1</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_endbal_tier_gral.end_balance_tier_1)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Ending Balance Tier 0</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_endbal_tier_gral.end_balance_tier_0)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance Fee Wallet</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_endbal_tier_gral.end_balance_fee_wallet)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-5">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance Fee Wallet Silver</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_endbal_tier_gral.end_balance_fee_silver_wallet)}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* Headers New card fees*/}
                                <div className="p-grid dashboard">
                                    <div className="p-col-10 ta-left" style={{paddingBottom:0}}>
                                        <h3>Total Current Fees</h3>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>New Card Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_gral.New_Card_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Load Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_gral.Load_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Purchase Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_gral.Purchase_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Unload Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_gral.Unload_Fees)}</div>
                                        </div>
                                    </div>


                                    <div className="p-col-10 p-md-2 p-lg-2">
                                        <div className="p-grid overview-box overview-box-5">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Total Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_gral.total)}</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Fees History</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_history} height="50" />
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>

                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Loads History Master Account</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_load_history} height="50"/>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>


                                {/*Pie Count Charts*/}
                                <div className="p-grid">


                                    <div className="p-col-4">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Load Count</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="pie" data={this.state.report_airtm_load_fee} options={lightOptions}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>

                                    <div className="p-col-4">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Purchase Count</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="pie" data={this.state.report_airtm_purchase_fee} options={lightOptions}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>


                                    <div className="p-col-4">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Unload Count</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="pie" data={this.state.report_airtm_unload_fee} options={lightOptions}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>

                                </div>

                                {/*Pie Amount Charts*/}
                                <div className="p-grid">


                                    <div className="p-col-4">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Load Amount</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="pie" data={this.state.report_airtm_load_amt} options={lightOptions}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>

                                    <div className="p-col-4">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Purchase Amount</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="pie" data={this.state.report_airtm_purchase_amt} options={lightOptions}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>


                                    <div className="p-col-4">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Unload Amount</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="pie" data={this.state.report_airtm_unload_amount} options={lightOptions}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>
                                </div>


                                {/*Card activation serie*/}
                                <div className="p-grid">


                                    <div className="p-col-12">
                                        <Card>

                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Card Creation</TextSemiBold>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div>
                                                        <Chart type="bar" data={this.state.report_airtm_creation_fee_series} height="50"  />
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>
                                </div>


                                {/*Seccion de Mapas*/}
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <Card height="height-100">
                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Purchases from Country</TextSemiBold>
                                                </div>
                                            </div>
                                            <div className="p-grid">
                                                <div className="p-col-12">

                                                    {(this.state.map_compras !== null) && <MapplicWrapper worldMap={this.state.map_compras} worldMapID={'1'}/>}

                                                </div>
                                            </div>
                                        </Card>
                                    </div>

                                    <div className="p-col-6">
                                        <Card height="height-100">
                                            <div className="p-grid">
                                                <div className="mb-3 p-col-12 ta-left">
                                                    <TextSemiBold className="font-16">Issued Cards from Country</TextSemiBold>
                                                </div>
                                            </div>
                                            <div className="p-grid">
                                                <div className="p-col-12">

                                                    {(this.state.map_activations !== null) && <MapplicWrapper worldMap={this.state.map_activations} worldMapID={'2'}/>}

                                                </div>
                                            </div>
                                        </Card>
                                    </div>

                                </div>


                            </TabPanel>


                            <TabPanel header="Tier 1 Silver">

                                {/* Headers */}
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 ta-left" style={{paddingBottom:0}}>
                                        <h3>Master Account Tier 1 Silver</h3>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>Beginning Balance</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_silvervirtual.begin_balance)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Net Wallet Loads</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_silvervirtual.loadcard)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-6">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Wallet Load Reversals</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_silvervirtual.loadreversal)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-7">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Wallet Redeems</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_silvervirtual.redeemtransfer)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Net Card Loads</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_silvervirtual.fundtransfer)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_silvervirtual.end_balance)}</div>
                                        </div>
                                    </div>

                                </div>

                                {/* Serie Netloads*/}
                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Loads History Master Account Tier 1 Silver</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_load_history_silver_virtual} height="50"/>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>

                                {/* Headers Fees*/}
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>New Card Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_silver.New_Card_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Load Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_silver.Load_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Purchase Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_silver.Purchase_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Unload Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_silver.Unload_Fees)}</div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-5">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Total Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_silver.total)}</div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance Fee Wallet</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_fee_card_tier_silver.end_balance)}</div>
                                        </div>
                                    </div>

                                </div>

                                {/* Serie History*/}
                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Fees History</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_history_tier_silver_virtual} height="50" />
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>



                            </TabPanel>

                            <TabPanel header="Tier 1">
                                {/* Headers */}
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 ta-left" style={{paddingBottom:0}}>
                                        <h3>Master Account Tier 1</h3>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>Beginning Balance</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_1.begin_balance)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Net Wallet Loads</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_1.loadcard)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-6">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Wallet Load Reversals</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_1.loadreversal)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-7">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Wallet Redeems</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_1.redeemtransfer)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Net Card Loads</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_1.fundtransfer)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_1.end_balance)}</div>
                                        </div>
                                    </div>

                                </div>

                                {/* Serie Netloads*/}
                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Loads History Master Account Tier 1</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_load_history_tier_1} height="50"/>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>

                                {/* Headers Fees*/}
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>New Card Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_1.New_Card_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Load Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_1.Load_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Purchase Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_1.Purchase_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Unload Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_1.Unload_Fees)}</div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-5">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Total Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_1.total)}</div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance Fee Wallet</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_fee_card_tier_1.end_balance)}</div>
                                        </div>
                                    </div>

                                </div>

                                {/* Serie History*/}
                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Fees History</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_history_tier_1} height="50" />
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>

                                {/* Report Loads and Reversals*/}
                                <TabView>
                                    <TabPanel header='Report Loads and Reversals'>
                                        <div className="p-col-12">
                                            <Card height="height-100">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="datatable-style-best">
                                                            <DataTable value={this.state.lst_loads_reversal_tier_1}
                                                                       reorderableColumns={true}
                                                                       selectionMode="single" rows={200}
                                                                       resizableColumns={true}>
                                                                <Column field="date" header="Date"  className="ta-right"/>
                                                                <Column field="wallet_title" header="Card Program"/>
                                                                <Column field="loadcard" header="Load Card"/>
                                                                <Column field="loadreversal" header="Load Reversal"/>

                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                    </TabPanel>
                                    <TabPanel header='Report Fee Wallet'>
                                        <div className="p-col-12">
                                            <Card height="height-100">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="datatable-style-best">
                                                            <DataTable value={this.state.lst_feeloads_reversal_tier_1}
                                                                       reorderableColumns={true}
                                                                       selectionMode="single" rows={200}
                                                                       resizableColumns={true}>
                                                                <Column field="date" header="Date"  className="ta-right"/>
                                                                <Column field="wallet_title" header="Card Program"/>
                                                                <Column field="loadcard" header="Load Card"/>
                                                                <Column field="loadreversal" header="Load Reversal"/>

                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                    </TabPanel>
                                </TabView>


                            </TabPanel>

                            <TabPanel header="Tier 0">

                                {/* Headers */}
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 ta-left" style={{paddingBottom:0}}>
                                        <h3>Master Account Tier 0</h3>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>Beginning Balance</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_0.begin_balance)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Net Wallet Loads</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_0.loadcard)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-6">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Wallet Load Reversals</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_0.loadreversal)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-7">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Wallet Redeems</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_0.redeemtransfer)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Net Card Loads</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_0.fundtransfer)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_card_tier_0.end_balance)}</div>
                                        </div>
                                    </div>

                                </div>



                                {/* Serie Netloads  */}

                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Loads History Master Account Tier 0</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_load_history_tier_0} height="50"/>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>

                                {/* Headers Fees*/}
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-1">
                                            <div className="overview-box-title">
                                                <i className="fa fa-inbox"></i>
                                                <span>New Card Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_0.New_Card_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-2">
                                            <div className="overview-box-title">
                                                <i className="fa fa-map-pin"></i>
                                                <span>Load Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_0.Load_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-3">
                                            <div className="overview-box-title">
                                                <i className="fa fa-folder"></i>
                                                <span>Purchase Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_0.Purchase_Fees)}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Unload Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_0.Unload_Fees)}</div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-5">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Total Fees</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.info_dict_tier_0.total)}</div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4 p-lg-2">
                                        <div className="p-grid overview-box overview-box-4">
                                            <div className="overview-box-title">
                                                <i className="fa fa-user"></i>
                                                <span>Ending Balance Fee Wallet</span>
                                            </div>
                                            <div className="overview-box-count ta-right">{formatCurrency(this.state.report_net_fee_card_tier_0.end_balance)}</div>
                                        </div>
                                    </div>

                                </div>


                                {/* Serie History  */}
                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">Fee History Master Account Tier 0</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_history_tier_0} height="50" />
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>


                                <TabView>
                                    <TabPanel header='Report Loads and Reversals'>
                                        <div className="p-col-12">
                                            <Card height="height-100">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="datatable-style-best">
                                                            <DataTable value={this.state.lst_loads_reversal_tier_0}
                                                                       reorderableColumns={true}
                                                                       selectionMode="single" rows={200}
                                                                       resizableColumns={true}>
                                                                <Column field="date" header="Date"  className="ta-right"/>
                                                                <Column field="wallet_title" header="Card Program"/>
                                                                <Column field="loadcard" header="Load Card"/>
                                                                <Column field="loadreversal" header="Load Reversal"/>

                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                    </TabPanel>
                                    <TabPanel header='Report Fee Wallet'>
                                        <div className="p-col-12">
                                            <Card height="height-100">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="datatable-style-best">
                                                            <DataTable value={this.state.lst_feeloads_reversal_tier_0}
                                                                       reorderableColumns={true}
                                                                       selectionMode="single" rows={200}
                                                                       resizableColumns={true}>
                                                                <Column field="date" header="Date"  className="ta-right"/>
                                                                <Column field="wallet_title" header="Card Program"/>
                                                                <Column field="loadcard" header="Load Card"/>
                                                                <Column field="loadreversal" header="Load Reversal"/>

                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                    </TabPanel>
                                </TabView>
                            </TabPanel>

                        </TabView>
                    </Card>



                </div>

            </div>
        );
    }
}
export default DashboardAirtm;
