import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Chart} from "primereact/components/chart/Chart";
import { AutoComplete } from 'primereact/autocomplete';
import PropTypes from "prop-types";
import classNames from "classnames";



class TokenTransactions extends Component {

    constructor(props){
        super(props);
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listTrans: [],
            listTransactions: [],
            listTransactionsMswipe: [],
            listTransactionsWebHook: [],
            listAccount: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            globalFilter_trans: null,
            dataTableSelectValue: {},
            merchantData: {},
            typeTransactionData: {},
            dataSeriesAccount: {},
            infoProfile: {},
            chargedYet: true,
            chargedYet2: true,
            detailDialog: false,
            account_tkn_no: "*",
            detailProfile: false,
            cardToken:"*",
            balance:0,
            balance_mswipe:0,
            balance_tpp:0,

            profiles:[],
            setProfiles:[],
            selectedProfiles:null,
            setSelectedProfiles:null,
            filteredProfiles:null,
            setFilteredProfiles:null,

            selectedBrand: null,
            filteredBrands: [],

            status_token_ewi:''
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.actionTransactions = this.actionTransactions.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.getBalanceCardToken = this.getBalanceCardToken.bind(this);
        this.loadProfiles = this.loadProfiles.bind(this);
        this.loadAccounts = this.loadAccounts.bind(this);
        this.setSelectedProfiles = this.setSelectedProfiles.bind(this);
        this.setFilteredProfiles = this.setFilteredProfiles.bind(this);
        this.filterBrands = this.filterBrands.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.loadSumary = this.loadSumary.bind(this);
        this.getStatusCardDetail = this.getStatusCardDetail.bind(this);
    }

    static defaultProps = {
        transaction: null,
    }
    static propTypes = {
        transaction: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.loadProfiles();

        if(this.props.transaction != null){
            this.usuarioService = new UsuarioService();
            this.setState({
                selectedBrand: this.props.transaction.email,
                cardToken: this.props.transaction.card_token,
            });
            this.loadAccounts(this.props.transaction.email);
            setTimeout(()=>{
                this.callListPrincipal();
            },500);

            this.getStatusCardDetail();

        }
    }

    loadProfiles(){

        this.usuarioService = new UsuarioService()
        this.usuarioService.getAllBestProfiles().then(resp => {
            if(resp.status === 'success'){
                const profiles = resp.data;
                this.setState({
                    profiles});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    loadSumary(){
        //Add Sumary
        let email = this.state.selectedBrand;
        let account_tkn_no = this.state.cardToken;
        this.usuarioService.getReportSumaryAccount(email,account_tkn_no).then(resp => {
            if(resp.status === 'success'){
                const merchantData = resp.report_merchant;
                const typeTransactionData = resp.report_type_transaction;
                const dataSeriesAccount = resp.report_serie;



                this.setState({typeTransactionData});
                this.setState({merchantData});
                this.setState({dataSeriesAccount});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    loadAccounts(email){

        let selectedBrand = email;
        this.setState({selectedBrand});


        this.usuarioService = new UsuarioService()
        this.usuarioService.getAccountList(selectedBrand).then(resp => {
            if(resp.status === 'success'){
                const listAccount = resp.data;
                this.setState({
                    listAccount});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

        this.usuarioService.getInfoProfileBest(email).then(resp => {
            if(resp.status === 'success'){
                const infoProfile = resp.data;
                this.setState({
                    infoProfile});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });




    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });



    }

    callListPrincipal(){
        this.getBalanceCardToken()
        this.usuarioService = new UsuarioService()
        let params = {card_token:this.state.cardToken}
        this.usuarioService.postTransCardTokenBalance(params).then(resp => {
            if(resp.status === 'success'){
                const listTransactions = resp.data.transactions
                const listTransactionsMswipe = resp.data.transactions_mswipe;
                const listTransactionsWebHook = resp.data.transactions_webhook;
                const balance = resp.data.balance
                const balance_mswipe = resp.data.balance_mswipe

                this.setState({
                    listTransactions,balance,listTransactionsMswipe,balance_mswipe,
                    listTransactionsWebHook,
                    chargedYet: true});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
        this.loadSumary();
    }

    getBalanceCardToken(){
        this.usuarioService = new UsuarioService()
        let params = {card_token:this.state.cardToken}
        this.usuarioService.postBalanceCardTokenTpp(params).then(resp => {
            if(resp.status === 'success'){
                const balance_tpp = resp.data.balance

                this.setState({
                    balance_tpp,
                    chargedYet: true});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        this.setState({
            detailDialog: true
        });
    }

    actionTransactions() {
        this.setState({
            code:"",
            operation:"payment",
            detailProfile: true,
            chargedYet2:false
        });

        this.usuarioService = new UsuarioService()
        if ('email' in this.state.dataTableSelectValue){
            let email =this.state.dataTableSelectValue.email
            let account_tkn_no =this.state.account_tkn_no
            this.usuarioService.getTransactionList(email,account_tkn_no).then(resp => {
                if(resp.status === 'success'){
                    const listTrans = resp.data

                    this.setState({
                        listTrans,
                        chargedYet2: true});

                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });

            //Add Sumary
            this.usuarioService.getReportSumaryAccount(email,account_tkn_no).then(resp => {
                if(resp.status === 'success'){
                    const merchantData = resp.report_merchant;
                    const typeTransactionData = resp.report_type_transaction;
                    const dataSeriesAccount = resp.report_serie;



                    this.setState({typeTransactionData});
                    this.setState({merchantData});
                    this.setState({dataSeriesAccount});

                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });





        }



    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            detailDialog: false,
        });
    }
    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    setSelectedProfiles(value){
        let selectedProfiles = value;
        this.setState({selectedProfiles})

    }

    setFilteredProfiles(value){
        let setFilteredProfiles = value;
        this.setState({setFilteredProfiles})
    }


    filterBrands(event) {
        setTimeout(() => {
            let filteredBrands;

            if (event.query.length === 0) {
                filteredBrands = [...this.state.profiles];
            }
            else {
                filteredBrands = this.state.profiles.filter((profile) => {
                    return profile.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({filteredBrands});
        }, 250);
    }

    itemTemplate(brand) {
        return (
            <div className="p-clearfix">
                <div style={{ fontSize: '16px', float: 'right', margin: '10px 10px 0 0' }}>{brand}</div>
            </div>
        );
    }

    getStatusCardDetail(){
        const status_token_ewi = 'Without card';
        this.usuarioService = new UsuarioService();
        let params = {card_token: this.props.transaction.card_token}
        this.usuarioService.postTokenStatusCardDetail(params).then(resp => {
            if(resp.code === 200){
                const data = resp.data;
                const status_token_ewi = data.status_token_ewi == 1 ? 'BLOCK_CARD' : data.status_token_ewi == 2 ? 'STOLEN_CARD' : 'ACTIVE';
                this.setState({status_token_ewi});
            }else if(resp.code === 204){
                this.setState({status_token_ewi});
            }else {
                this.setState({status_token_ewi});
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    render() {
        const header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;

        const refundFooter = (
            <React.Fragment>
                <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
            </React.Fragment>
        );

        const {
            cardToken,
            email,
            profiles,
        } = this.state;

        const bodyTransTemplateResp = (rowData,column) => {
            const stockClassName = classNames({
                'lowstock': rowData[column.field] === 'Declined',
                'instock': rowData[column.field] === 'Approved'
            });

            return (
                <div className={stockClassName}>
                    {rowData[column.field]}
                </div>
            );
        }

        const bodyTransTemplateAmt = (rowData,column) => {
            const stockClassName = classNames({
                'lowstock': rowData[column.field] <= 0,
                'instock': rowData[column.field] > 0
            });

            return (
                <div className={stockClassName}>
                    {rowData[column.field]}
                </div>
            );
        }


        return(
            <div className="pageWrapper">

                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-8">

                        <Card height="height-100">
                            <CardTitle>Card Balance</CardTitle>
                            <div className="p-grid">
                                <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                                    {this.state.chargedYet ? '' :
                                        <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>}
                                </div>
                            </div>


                            <Toolbar className="p-mb-4 mb-10">

                                <label htmlFor="cardToken">Email</label>
                                <AutoComplete value={this.state.selectedBrand} suggestions={this.state.filteredBrands} completeMethod={this.filterBrands} size={30} minLength={1}
                                              placeholder="Hint: type email" dropdown={true} itemTemplate={this.itemTemplate} field="email" children="email"
                                              onChange={(e) => this.loadAccounts(e.value)} />

                                <label htmlFor="cardToken">Card Tokens</label>
                                <Dropdown value={this.state.cardToken} options={this.state.listAccount} onChange={(e) => {
                                    this.setState({cardToken: e.value})
                                }} className="mr-5"/>

                                <Button label="Search" onClick={this.callListPrincipal} />
                            </Toolbar>

                            <div className="p-col-12 p-md-4 p-lg-4">
                                <table className="p-datatable p-datatable-sm-light mt-10"
                                       style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td ><span>Balance Card:    </span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.balance_tpp}</span></td>
                                    </tr>
                                    <tr>
                                        <td ><span>Name:</span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.infoProfile.first_name} {this.state.infoProfile.last_name}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td ><span>Phone:</span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.infoProfile.phone} {this.state.infoProfile.cell_phone}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td ><span>Address:</span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.infoProfile.address_1} {this.state.infoProfile.address_2}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td ><span>City:</span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.infoProfile.city} {this.state.infoProfile.state}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td ><span>Country:</span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.infoProfile.country}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td ><span>Card token:</span></td>
                                        <td style={{width: '50%'}} className="ta-right">
                                            <span>{this.state.status_token_ewi}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>

                    <div className="p-col-4 p-lg-4">
                        <Card height="height-50">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Type Transactions</CardTitle>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-8">
                                    <div>
                                        <Chart type="pie" data={this.state.typeTransactionData} height="300" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>


                    <div className="p-col-8">
                        <Card height="height-50" id="dataProfile">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Transaction Series</CardTitle>
                            </div>

                            <div>
                                <Chart type="line" data={this.state.dataSeriesAccount} height="95"/>
                            </div>
                        </Card>
                    </div>


                    <div className="p-col-4 p-lg-4">
                        <Card height="height-50">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Merchants</CardTitle>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-8">
                                    <div>
                                        <Chart type="pie" data={this.state.merchantData} height="300" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="p-col-12">
                        <Card height="height-100">
                            <TabView>
                                <TabPanel header="Best">

                                    <DataTable value={this.state.listTransactions}
                                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                                               selectionMode="single" paginator={true} rows={100}
                                               resizableColumns={true}
                                               selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                        <Column selectionMode="single" style={{width:'3em'}}/>
                                        <Column field="transaction_id" header="Trans" sortable={true} className="ta-right"/>
                                        <Column field="account_tkn_no" header="Card Token" sortable={true} className="ta-right"/>
                                        <Column field="card_program_id" header="Program Id" sortable={true}/>
                                        <Column field="type" header="Type" sortable={true} className="ta-right"/>
                                        <Column field="amount2" header="Amount" sortable={true} body={bodyTransTemplateAmt}/>
                                        <Column field="balance_before" header="Before" sortable={true} body={bodyTransTemplateAmt}/>
                                        <Column field="balance_after" header="After" sortable={true} body={bodyTransTemplateAmt}/>
                                        <Column field="response" header="Response" sortable={true} body={bodyTransTemplateResp}/>
                                        <Column field="date_created" header="Created" sortable={true} className="ta-right"/>
                                    </DataTable>


                                    <Dialog visible={this.state.detailDialog} style={{width: '900px', height: '600px'}}
                                            header="Detail Transaction" modal className="p-fluid" footer={refundFooter}
                                            onHide={this.hideDialog}>
                                        <div className="p-field">
                                            <p>id:  <b>{this.state.dataTableSelectValue.id}</b></p>
                                            <p>account_tkn_no:  <b>{this.state.dataTableSelectValue.account_tkn_no}</b></p>
                                            <p>card_status:  <b>{this.state.dataTableSelectValue.card_status}</b></p>
                                            <p>card_program_id:  <b>{this.state.dataTableSelectValue.card_program_id}</b></p>
                                            <p>card_program_name:  <b>{this.state.dataTableSelectValue.card_program_name}</b></p>
                                            <p>transaction_id:  <b>{this.state.dataTableSelectValue.transaction_id}</b></p>
                                            <p>wallet_account_no:  <b>{this.state.dataTableSelectValue.wallet_account_no}</b></p>
                                            <p>customer_id:  <b>{this.state.dataTableSelectValue.customer_id}</b></p>
                                            <p>merchant_id:  <b>{this.state.dataTableSelectValue.merchant_id}</b></p>
                                            <p>location_id:  <b>{this.state.dataTableSelectValue.location_id}</b></p>
                                            <p>terminal_id:  <b>{this.state.dataTableSelectValue.terminal_id}</b></p>
                                            <p>transaction_type_id:  <b>{this.state.dataTableSelectValue.transaction_type_id}</b></p>
                                            <p>un_collected:  <b>{this.state.dataTableSelectValue.un_collected}</b></p>
                                            <p>type:  <b>{this.state.dataTableSelectValue.type}</b></p>
                                            <p>amount:  <b>{this.state.dataTableSelectValue.amount}</b></p>
                                            <p>date_created:  <b>{this.state.dataTableSelectValue.date_created}</b></p>
                                            <p>date_collected:  <b>{this.state.dataTableSelectValue.date_collected}</b></p>
                                            <p>user_id:  <b>{this.state.dataTableSelectValue.user_id}</b></p>
                                            <p>username:  <b>{this.state.dataTableSelectValue.username}</b></p>
                                            <p>user_merchant_id:  <b>{this.state.dataTableSelectValue.user_merchant_id}</b></p>
                                            <p>api_username:  <b>{this.state.dataTableSelectValue.api_username}</b></p>
                                            <p>api_user_id:  <b>{this.state.dataTableSelectValue.api_user_id}</b></p>
                                            <p>balance_before:  <b>{this.state.dataTableSelectValue.balance_before}</b></p>
                                            <p>balance_after:  <b>{this.state.dataTableSelectValue.balance_after}</b></p>
                                            <p>un_coll_bal_before:  <b>{this.state.dataTableSelectValue.un_coll_bal_before}</b></p>
                                            <p>un_coll_bal_after:  <b>{this.state.dataTableSelectValue.un_coll_bal_after}</b></p>
                                            <p>over_limit_pwd:  <b>{this.state.dataTableSelectValue.over_limit_pwd}</b></p>
                                            <p>over_limit_pwd_userid:  <b>{this.state.dataTableSelectValue.over_limit_pwd_userid}</b></p>
                                            <p>voided_transaction_id:  <b>{this.state.dataTableSelectValue.voided_transaction_id}</b></p>
                                            <p>voided:  <b>{this.state.dataTableSelectValue.voided}</b></p>
                                            <p>last_update:  <b>{this.state.dataTableSelectValue.last_update}</b></p>
                                            <p>pending_balance_type:  <b>{this.state.dataTableSelectValue.pending_balance_type}</b></p>
                                            <p>payment_type_id:  <b>{this.state.dataTableSelectValue.payment_type_id}</b></p>
                                            <p>settlement_cycle_in_days:  <b>{this.state.dataTableSelectValue.settlement_cycle_in_days}</b></p>
                                            <p>transaction_response_id:  <b>{this.state.dataTableSelectValue.transaction_response_id}</b></p>
                                            <p>sml_3_setl_date_added_to_sm:  <b>{this.state.dataTableSelectValue.sml_3_setl_date_added_to_sm}</b></p>
                                            <p>sml_id:  <b>{this.state.dataTableSelectValue.sml_id}</b></p>
                                            <p>automatic:  <b>{this.state.dataTableSelectValue.automatic}</b></p>
                                            <p>forced_post:  <b>{this.state.dataTableSelectValue.forced_post}</b></p>
                                            <p>comment:  <b>{this.state.dataTableSelectValue.comment}</b></p>
                                            <p>card_funding_type_id:  <b>{this.state.dataTableSelectValue.card_funding_type_id}</b></p>
                                            <p>pseudo_dda_trace_number:  <b>{this.state.dataTableSelectValue.pseudo_dda_trace_number}</b></p>
                                            <p>pseudo_dda_sending_aba_no:  <b>{this.state.dataTableSelectValue.pseudo_dda_sending_aba_no}</b></p>
                                            <p>pseudo_dda_no:  <b>{this.state.dataTableSelectValue.pseudo_dda_no}</b></p>
                                            <p>pseudo_dda_customer_id:  <b>{this.state.dataTableSelectValue.pseudo_dda_customer_id}</b></p>
                                            <p>pseudo_dda_company_name:  <b>{this.state.dataTableSelectValue.pseudo_dda_company_name}</b></p>
                                            <p>pseudo_dda_transaction_date:  <b>{this.state.dataTableSelectValue.pseudo_dda_transaction_date}</b></p>
                                            <p>pseudo_dda_customer_name:  <b>{this.state.dataTableSelectValue.pseudo_dda_customer_name}</b></p>
                                            <p>mcc_code:  <b>{this.state.dataTableSelectValue.mcc_code}</b></p>
                                            <p>terminal_identification:  <b>{this.state.dataTableSelectValue.terminal_identification}</b></p>
                                            <p>terminal_location:  <b>{this.state.dataTableSelectValue.terminal_location}</b></p>
                                            <p>merchant_identification_code:  <b>{this.state.dataTableSelectValue.merchant_identification_code}</b></p>
                                            <p>forwarding_ints_identification_code_003:  <b>{this.state.dataTableSelectValue.forwarding_ints_identification_code_003}</b></p>
                                            <p>authotization_identification_response_038:  <b>{this.state.dataTableSelectValue.authotization_identification_response_038}</b></p>
                                            <p>retrieval_reference_number_037:  <b>{this.state.dataTableSelectValue.retrieval_reference_number_037}</b></p>
                                            <p>message_type_identifier_sent:  <b>{this.state.dataTableSelectValue.message_type_identifier_sent}</b></p>
                                            <p>receive_message_id_sent:  <b>{this.state.dataTableSelectValue.receive_message_id_sent}</b></p>
                                            <p>response_code_039_sent:  <b>{this.state.dataTableSelectValue.response_code_039_sent}</b></p>
                                            <p>message_type_identifier_receive:  <b>{this.state.dataTableSelectValue.message_type_identifier_receive}</b></p>
                                            <p>tran_location_merchant_name:  <b>{this.state.dataTableSelectValue.tran_location_merchant_name}</b></p>
                                            <p>linked_transaction_id:  <b>{this.state.dataTableSelectValue.linked_transaction_id}</b></p>
                                            <p>un_coll_fee_bal_before:  <b>{this.state.dataTableSelectValue.un_coll_fee_bal_before}</b></p>
                                            <p>un_coll_fee_bal_after:  <b>{this.state.dataTableSelectValue.un_coll_fee_bal_after}</b></p>
                                            <p>show_in_transaction_report:  <b>{this.state.dataTableSelectValue.show_in_transaction_report}</b></p>
                                            <p>transaction_response_approved:  <b>{this.state.dataTableSelectValue.transaction_response_approved}</b></p>
                                            <p>voided_automatically:  <b>{this.state.dataTableSelectValue.voided_automatically}</b></p>
                                            <p>purchase_completed:  <b>{this.state.dataTableSelectValue.purchase_completed}</b></p>
                                            <p>icc_cryptogram_arqc_tag9f26:  <b>{this.state.dataTableSelectValue.icc_cryptogram_arqc_tag9f26}</b></p>
                                            <p>status:  <b>{this.state.dataTableSelectValue.status}</b></p>
                                            <p>system_trace_audit_number_011_sent:  <b>{this.state.dataTableSelectValue.system_trace_audit_number_011_sent}</b></p>
                                            <p>time_local_transaction_012_sent:  <b>{this.state.dataTableSelectValue.time_local_transaction_012_sent}</b></p>
                                            <p>date_local_tranaction_013_sent:  <b>{this.state.dataTableSelectValue.date_local_tranaction_013_sent}</b></p>
                                            <p>date_settlement_015_sent:  <b>{this.state.dataTableSelectValue.date_settlement_015_sent}</b></p>
                                            <p>additional_amounts_054:  <b>{this.state.dataTableSelectValue.additional_amounts_054}</b></p>
                                            <p>is_internal:  <b>{this.state.dataTableSelectValue.is_internal}</b></p>
                                            <p>international:  <b>{this.state.dataTableSelectValue.international}</b></p>
                                            <p>currency_code:  <b>{this.state.dataTableSelectValue.currency_code}</b></p>
                                            <p>client_reference_id:  <b>{this.state.dataTableSelectValue.client_reference_id}</b></p>
                                            <p>sml_3_setl_date_added_to_sm_dateformat:  <b>{this.state.dataTableSelectValue.sml_3_setl_date_added_to_sm_dateformat}</b></p>
                                            <p>settled_balance_before:  <b>{this.state.dataTableSelectValue.settled_balance_before}</b></p>
                                            <p>settled_balance_after:  <b>{this.state.dataTableSelectValue.settled_balance_after}</b></p>
                                            <p>pending_balance_before:  <b>{this.state.dataTableSelectValue.pending_balance_before}</b></p>
                                            <p>pending_balance_after:  <b>{this.state.dataTableSelectValue.pending_balance_after}</b></p>
                                            <p>country_code:  <b>{this.state.dataTableSelectValue.country_code}</b></p>
                                            <p>request_amount:  <b>{this.state.dataTableSelectValue.request_amount}</b></p>
                                            <p>point_of_service_entry_mode_022:  <b>{this.state.dataTableSelectValue.point_of_service_entry_mode_022}</b></p>
                                            <p>amount_currency_code:  <b>{this.state.dataTableSelectValue.amount_currency_code}</b></p>
                                            <p>amount2:  <b>{this.state.dataTableSelectValue.amount2}</b></p>
                                        </div>
                                    </Dialog>
                                </TabPanel>
                                <TabPanel header="Mswipe">
                                    <DataTable value={this.state.listTransactionsMswipe}
                                               header='Mswipe' reorderableColumns={true}
                                               selectionMode="single" paginator={true} rows={100}
                                               resizableColumns={true}>
                                        <Column selectionMode="single" style={{width:'3em'}}/>
                                        <Column field="transaction_identifier" header="Trans" sortable={true} className="ta-right"/>
                                        <Column field="merchant_name" header="Merchant" sortable={true} className="ta-right"/>
                                        <Column field="card_token" header="Card Token" sortable={true} className="ta-right"/>
                                        <Column field="card_number" header="Card" sortable={true} className="ta-right"/>
                                        <Column field="type_card" header="Type Card" sortable={true} className="ta-right"/>
                                        <Column field="transaction_type" header="Type" sortable={true} className="ta-right"/>
                                        <Column field="type" header="Response" sortable={true} className="ta-right"/>
                                        <Column field="posted_amount" header="Amount" sortable={true} body={bodyTransTemplateAmt}/>
                                        <Column field="request_amount" header="RAmount" sortable={true} body={bodyTransTemplateAmt}/>
                                        <Column field="requested_currency" header="Currency" sortable={true} className="ta-right"/>
                                        <Column field="country" header="Country" sortable={true} className="ta-right"/>
                                        <Column field="date_created" header="Created" sortable={true} className="ta-right"/>
                                    </DataTable>

                                </TabPanel>

                                <TabPanel header="WebHook">
                                    <DataTable value={this.state.listTransactionsWebHook}
                                               header='WebHook' reorderableColumns={true}
                                               selectionMode="single" paginator={true} rows={100}
                                               resizableColumns={true}>
                                        <Column selectionMode="single" style={{width:'3em'}}/>
                                        <Column field="created" header="Created" sortable={true} className="ta-right"/>
                                        <Column field="transaction_identifier" header="ID" sortable={true} className="ta-right"/>
                                        <Column field="card_token" header="Card Token" sortable={true}/>
                                        <Column field="merchant_name" header="Merchant Name" sortable={true} className="ta-right"/>
                                        <Column field="amount" header="Amount" sortable={true} body={bodyTransTemplateAmt}/>
                                        <Column field="merchant_country" header="Country" sortable={true} className="ta-right"/>
                                        <Column field="transaction_type" header="Type" sortable={true} className="ta-right"/>
                                        <Column field="requested_currency" header="Currency" sortable={true} className="ta-right"/>
                                        <Column field="authorization_code" header="Authorization Code" sortable={true} className="ta-right"/>
                                        <Column field="program_id" header="Program" sortable={true} className="ta-right"/>
                                        <Column field="last4_pan" header="Last 4 digits" sortable={true} className="ta-right"/>
                                        <Column field="response" header="Response" sortable={true} body={bodyTransTemplateResp}/>
                                        <Column field="merchant_category_code" header="Category Name" sortable={true} className="ta-right"/>
                                    </DataTable>

                                </TabPanel>

                            </TabView>
                        </Card>
                    </div>

                </div>

            </div>







        );
    }
}
export default TokenTransactions;
