import React, { Component } from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppInlineProfile} from './AppInlineProfile';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppConfig} from './AppConfig';
import {Route, Redirect} from 'react-router-dom';
import { withRouter }    from 'react-router'
import {DashboardBanking} from './components/DashboardBanking';
import {FormsDemo} from './components/FormsDemo';
import {SampleDemo} from './components/SampleDemo';
import {DataDemo} from './components/DataDemo';
import {PanelsDemo} from './components/PanelsDemo';
import {OverlaysDemo} from './components/OverlaysDemo';
import {MenusDemo} from './components/MenusDemo';
import {MessagesDemo} from './components/MessagesDemo';
import {ChartsDemo} from './components/ChartsDemo';
import {MiscDemo} from './components/MiscDemo';
import {EmptyPage} from './components/EmptyPage';
import {Documentation} from './components/Documentation';
import {UtilsDemo} from './components/UtilsDemo';
import {Help} from './pages/Help';
import {Invoice} from "./pages/Invoice";

import ReportApisHome from "./components/apis";
import InvoicePaymentRequestHistory from "./components/InvoicePaymentRequestHistory";
import InvoiceReport from "./components/InvoiceReport";
import PendingWalletSettlementReport from "./components/PendingWalletSettlementReport";
import RequestInvoicePayment from "./components/RequestInvoicePayment";
import SettlementBatchDetail from "./components/SettlementBatchDetail";
import SettlementBatchReport from "./components/SettlementBatchReport";
import TransactionDetailReport from "./components/TransactionDetailReport";
import TransactionHistory from "./components/TransactionHistory";
import TransactionSnapshotReport from "./components/TransactionSnapshotReport";
import TransactionSummaryReport from "./components/TransactionSummaryReport";
import WalletSettlementBatchReport from "./components/WalletSettlementBatchReport";
import InvoicePayment from "./components/invoicePayment/invoicePayment";
import FeeReportLevel0 from "./components/feeReports";
import FeeReportLevel1 from "./components/feeReports/indexLevel1";
import FeeReportLevel2 from "./components/feeReports/indexLevel2";
import FeeReportLevel3 from "./components/feeReports/indexLevel3";
import BtcHome from "./components/btc_invoice";
import DonationHome from "./components/donation";

import {UsuarioService} from "./service/login/UsuarioService";
import { AuthService } from './service/login/AuthService';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'font-awesome/css/font-awesome.css';
import 'primereact/resources/primereact.min.css';
import './App.css';

import InvoicePaymentBtc from "./components/invoicePaymentBtc";
import Profile from "./components/profile";
import SettingsBtc from "./components/btc_invoice/settings_btc";
import ExternalBtcHome from "./components/external_btc";
import WebHookBestHome from "./components/webhook_best";
import WebHookAirTmHome from "./components/webhook_airtm";
import AlertHome from "./components/webhook_alerts";
import DepositHome from "./components/webhook_deposits";
import WebHookLogHome from "./components/webhook_logs";

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            profileMode: 'top',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: true,
            menuActive: false,
            theme: 'cyan',
            layout: 'lightblue',
            version: 'v4',
			configDialogActive: false,

			logout: false,
			usuarioSesion:null,
			login : false,
			gridObjeto:{},
			mostrarBandejaEnFooter:false,
			configObjecto: {},
			recibidosTopbar:0,
			atendidosTopbar:0,
			activeIndexBandeja:0
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
		this.changeMenuMode = this.changeMenuMode.bind(this);
		this.changeMenuColor = this.changeMenuColor.bind(this);
		this.changeProfileMode = this.changeProfileMode.bind(this);
		this.changeVersion = this.changeVersion.bind(this);
		this.changeLayout = this.changeLayout.bind(this);
		this.changeTheme = this.changeTheme.bind(this);
		this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
		this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
		this.onConfigClick = this.onConfigClick.bind(this);
		this.onHandleClickProfile = this.onHandleClickProfile.bind(this);

		this.validarSesion = this.validarSesion.bind(this);
		this.usuarioService = new UsuarioService();
        this.createMenu();
		this.authService = new AuthService();
		this.actualizarTotalTopbar = this.actualizarTotalTopbar.bind(this);
		this.actionToAccount = this.actionToAccount.bind(this);
    }
    componentDidMount() {
        let role = sessionStorage.getItem('role');

        this.setState({ role: role });
        this.createMenu();

        this.changeLayout({layout: 'lightblue'});

        const second = 60000;
        const minutes = 0.3 * second;
        setInterval(() => {
            const current_time = Date.now() / 1000;
            const expiration = Number.parseInt(sessionStorage.getItem("expires_date"));
            if ( expiration < current_time) {
                this.onLogout();
            }
        }, minutes);
	}

	onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if(this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if(this.isDesktop())
                this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
            else
                this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if(this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.hideOverlayMenu();
        }
        if(!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

	onConfigButtonClick(event){
		this.configClick = true;
		this.setState({configDialogActive: !this.state.configDialogActive})
	}

	onConfigCloseClick(){
		this.setState({configDialogActive: false})
	}

	onConfigClick(){
		this.configClick = true;
	}

    onDocumentClick(event) {
        if(!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if(!this.menuClick) {
            if(this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

		if (!this.configClick) {
			this.setState({configDialogActive: false});
		}

        if(!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
		this.configClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

	changeMenuMode(event) {
		this.setState({layoutMode: event.layoutMode})
		if(event.layoutMode === 'horizontal') {
			this.setState({profileMode: 'top'})
		}
    }

	changeMenuColor(event) {
		this.setState({darkMenu: event.darkMenu})
	}

	changeProfileMode(event) {
		this.setState({profileMode: event.profileMode})
	}

	changeVersion(event) {
		this.setState({version:event.version});
		if(event.version === 'v3'){
			this.changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
			this.changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
		} else {
			this.changeStyleSheetUrl('layout-css', this.state.layout+'-v4', 'layout');
			this.changeStyleSheetUrl('theme-css', this.state.theme+'-v4', 'theme');
		}
	}

	changeLayout(event) {
		this.setState({layout: event.layout});
		if(this.state.version === 'v3'){
			this.changeStyleSheetUrl('layout-css', event.layout, 'layout');
		} else {
			this.changeStyleSheetUrl('layout-css', event.layout+'-v4', 'layout');
		}

		if(event.special) {
			this.setState({
				darkMenu: true
			})
		}
	}

    changeTheme(event) {
        this.setState({theme: event.theme});
        if(this.state.version === 'v3'){
            this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', event.theme+'-v4', 'theme');
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

		this.replaceLink(element, newURL);
    }

	isIE() {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
	}

	replaceLink(linkElement, href) {
		if(this.isIE()){
			linkElement.setAttribute('href', href);
		}
		else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		}
	}

	/**
	 * Metodo para obtner el usuario de la sesion y validarlo
	 * params:
	 * return: usuarioSesion
	 **/
	validarSesion(){
		let usuarioSesion = JSON.parse(sessionStorage.getItem('usuarioSesion'));
        let isLogin = JSON.parse(sessionStorage.getItem('login'));
        if(isLogin){
            this.setState({login : true});
        }else{
            this.setState({login : false});
        }
		if(usuarioSesion){
			this.setState({usuarioSesion});
			//console.log("usuarioSesion.idUsuario:",usuarioSesion.idUsuario);
			return usuarioSesion.idUsuario;
		}else{
			return '';
		}
	}

    onLogout(){
        sessionStorage.clear();
        let logout = true;
        if (this !== undefined){
            if(this.handle){
                clearInterval(this.handle);
            }
            this.setState({
                logout
            });
        }
    }
    onHandleClickProfile = (e) =>  {
        this.props.history.push("/profile");
    }

    createMenu() {
	    const self = this;
		self.usuarioService.getUsuarioLogueo().then(resp => {
            let role = resp.data.profile.rol;
            self.setState({login: true});
            sessionStorage.setItem('userNameSesion', self.state.username);
            sessionStorage.setItem('role', role);
            this.props.history.push("/best");
            self.menu = [];

            if (role === 'root') {

                self.menu.push({label: 'Dashboard', icon: 'fa fa-fw  fa-credit-card', to: '/best'});
                self.menu.push({label: 'Alerts', icon: 'fa fa-fw  fa-credit-card', to: '/alert'});
                self.menu.push({label: 'WebHook Logs', icon: 'fa fa-fw  fa-credit-card', to: '/webhook_log'});
                self.menu.push({label: 'Deposits', icon: 'fa fa-fw  fa-credit-card', to: '/deposit'});
            }

            self.menu.push({label: 'Logout ', icon: 'fa fa-sign-out', to: '/login', command: () => self.onLogout()});


		}).catch(error => {
            if (error.response != undefined && error.response.status === 401) {
                self.onLogout();
            }
			console.log(error);
		});
    }

	actionToAccount () {
		console.log("Em App actionToAccount")
		this.setState({opcionMenuSelected: 'Account'});
	}

	/**
	 * Metodo para pasar el total de operaciones al topbar
	 */
	actualizarTotalTopbar(recibidosTopbar, atendidosTopbar){
		this.setState({
			recibidosTopbar,
			atendidosTopbar
		});
	}

    render() {
		if(JSON.parse(sessionStorage.getItem('login') == null)) {
			return (<Redirect to={`/login`}/>)
		}

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                            topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                            onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                            onTopbarItemClick={this.onTopbarItemClick} onLogout={this.onLogout}
                            profile={{'userName': sessionStorage.getItem("userLogged"), 'area': ''}}
                            onHandleClickProfile={this.onHandleClickProfile}
                    />

                    <div className={menuClassName} onClick={this.onMenuClick}>
						<div className="menu-scroll-content">
							{(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') &&
                                <AppInlineProfile onLogout={this.onLogout} profile={{'userName': sessionStorage.getItem("userLogged"), 'area': ''}}
                                                  onHandleClickProfile={this.onHandleClickProfile}/>}
							<AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
									layoutMode={this.state.layoutMode} active={this.state.menuActive} />
						</div>
                    </div>

                    <div className="layout-main">
                        <Route path="/" exact component={EmptyPage} />
                        <Route path="/dashboard_banking" exact component={DashboardBanking} />
                        <Route path="/forms" component={FormsDemo} />
                        <Route path="/sample" component={SampleDemo} />
                        <Route path="/data" component={DataDemo} />
                        <Route path="/panels" component={PanelsDemo} />
                        <Route path="/overlays" component={OverlaysDemo} />
                        <Route path="/menus" component={MenusDemo} />
                        <Route path="/messages" component={MessagesDemo} />
                        <Route path="/charts" component={ChartsDemo} />
                        <Route path="/misc" component={MiscDemo} />
                        <Route path="/empty" component={EmptyPage} />
                        <Route path="/utils" component={UtilsDemo} />
                        <Route path="/documentation" component={Documentation} />
                        <Route path="/help" component={Help} />
                        <Route path="/invoice" component={Invoice} />

                        <Route path='/apis' component={ReportApisHome} />
                        <Route path='/invoice_payment_request_history' component={InvoicePaymentRequestHistory} />
                        <Route path='/invoice_report' component={InvoiceReport} />
                        <Route path='/pending_wallet_settlement_report' component={PendingWalletSettlementReport} />
                        <Route path='/request_invoice_payment' component={RequestInvoicePayment} />
                        <Route path='/settlement_batch_detail' component={SettlementBatchDetail} />
                        <Route path='/settlement_batch_report' component={SettlementBatchReport} />
                        <Route path='/transaction_detail_report' component={TransactionDetailReport} />
                        <Route path='/transaction_history' component={TransactionHistory} />
                        <Route path='/transaction_snapshot_report' component={TransactionSnapshotReport} />
                        <Route path='/transaction_summary_report' component={TransactionSummaryReport} />
                        <Route path='/wallet_settlement_batch_report' component={WalletSettlementBatchReport} />
                        <Route path='/invoice_payment' component={InvoicePayment} />
                        <Route path='/invoice_payment_btc' component={InvoicePaymentBtc} />
                        <Route path='/fee_report_level0' component={FeeReportLevel0} />
                        <Route path='/fee_report_level1' component={FeeReportLevel1} />
                        <Route path='/fee_report_level2' component={FeeReportLevel2} />
                        <Route path='/fee_report_level3' component={FeeReportLevel3} />
                        <Route path='/btc_dashboard' component={BtcHome} />
                        <Route path='/donation_home' component={DonationHome} />
                        <Route path='/profile' component={Profile} />
                        <Route path='/btc/settings' component={SettingsBtc} />
                        <Route path='/btc/external' component={ExternalBtcHome} />
                        <Route path='/best' component={WebHookBestHome} />
                        <Route path='/airtm' component={WebHookAirTmHome} />
                        <Route path='/alert' component={AlertHome} />
                        <Route path='/deposit' component={DepositHome} />
                        <Route path='/webhook_log' component={WebHookLogHome} />
                    </div>

                    <div className="layout-mask"></div>

					<AppConfig layoutMode={this.state.layoutMode} darkMenu={this.state.darkMenu} profileMode={this.state.profileMode}
							   version={this.state.version} layoutColor={this.state.layout} themeColor={this.state.theme}
							   changeMenuMode={this.changeMenuMode} changeMenuColor={this.changeMenuColor} changeProfileMode={this.changeProfileMode}
							   changeVersion={this.changeVersion} changeLayout={this.changeLayout} changeTheme={this.changeTheme}
							   onConfigButtonClick={this.onConfigButtonClick} onConfigCloseClick={this.onConfigCloseClick}
							   onConfigClick={this.onConfigClick} configDialogActive={this.state.configDialogActive} />

                    <AppFooter />
                </div>
            </div>
        );
  }
}

export default withRouter(App);
