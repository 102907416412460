import React, { Component } from 'react';
import Card from '../shared/Card';
import {Button} from "primereact/components/button/Button";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import TextSemiBold from "../shared/Typography/TextSemiBold";
import {Chart} from "primereact/components/chart/Chart";
import {formatCurrency, formatNumber} from "../utils/commonFunctions";
import {Calendar} from "primereact/calendar";
import {CarService} from "../../service/CarService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Growl} from "primereact/growl";
import {ProgressSpinner} from "primereact/progressspinner";
import {SelectButton} from "primereact/selectbutton";
import classNames from "classnames";
import {TabPanel, TabView} from "primereact/tabview";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {MapplicWrapper} from "../Wrapper/MapplicWrapper";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/components/dialog/Dialog";
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";

class DashboardLogs extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state = {
            data: new FormData(),
            fileName: "",
            merchantId: "",
            listMechants: [],
            listAddress: [],
            listFunWalletlog: [],
            listLoadReverTlog: [],
            rates: [],
            catBanks: [],
            bankId: "",
            header: {
                temple: 100.95,
                gifts: 250.25,
                causes_helps: 650.56,
                recurring: 1740.26
            },
            donation_date: '14 de octubre del 2020, 10:45 PM',
            donation_period: null,
            series_history: {},
        }

        this.getReporHistorySeries = this.getReporHistorySeries.bind(this);
    }

    componentDidMount() {
        this.getReporHistorySeries();
        this.callReset();
    }


    getReporHistorySeries(){

        this.usuarioService.getReportAirtmWebHookHistorySerie().then(resp => {
            if(resp.status === 'success'){
                const series_history = resp.data;
                this.showSucccess(resp.time_react)
                this.setState({series_history});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    callReset = () => {

    }

    render() {


        const mapCountry= {"US":33.0,"MX":2977.36,"GB":120.0,"HN":7.18};
        const mapCountryList= [{"country_code":"US","total_amount":33.0},{"country_code":"MX","total_amount":2977.36},{"country_code":"GB","total_amount":120.0},{"country_code":"HN","total_amount":7.18}];

        const lightOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };

        const options = {
            animation: false,
            scaleLabel:
                function(label){return  '$' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}
        };



        return(

            <div className="pageWrapper" >
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <Card height="height-100">
                        <TabView>
                            <TabPanel header="General">

                                {/*Serie webhook history*/}
                                <div className="p-col-12">
                                    <Card>

                                        <div className="p-grid">
                                            <div className="mb-3 p-col-12 ta-left">
                                                <TextSemiBold className="font-16">WebHook History</TextSemiBold>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div>
                                                    <Chart type="line" data={this.state.series_history} height="50" />
                                                </div>
                                            </div>
                                        </div>

                                    </Card>

                                </div>
                            </TabPanel>
                        </TabView>
                    </Card>
                </div>

            </div>
        );
    }
}
export default DashboardLogs;
