import React, { Component } from 'react';
import DashboardLogs from "./dashboardLogs";

class WebHookLogHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'WebhookLog',
        }

        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    render() {
        return (
            <div className="dashboard-banking">
                <div className="p-grid overview-boxes">
                    <div className="p-col-12 p-md-3 p-lg-3" onClick={this.showBox('WebhookLog')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-transfer.png" alt="avalon-layout"
                                     className="icon-transfer"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Webhook Logs</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                {(this.state.opcionMenuSelected==='WebhookLog') && <DashboardLogs />}
            </div>
        );
    }
}

export default WebHookLogHome;
