import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {formatCurrency} from "../utils/commonFunctions";
import {PaymentService} from "../../service/payment/PaymentService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {STATUS_TOKEN_CHOICES} from "../utils/constants";

class Transactions extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listTrans: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            dataTableSelectValue: {payment_desc:{}},
            chargedYet: false,
            detailDialog: false,
            balance_tpp: 0.0,
            previous:'',
            next:'',
            cardToken:'',

            status_token_ewi:'Without card'
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.getBalanceCardToken = this.getBalanceCardToken.bind(this);
        this.callListPage = this.callListPage.bind(this);
        this.callListPageNext = this.callListPageNext.bind(this);
        this.callListPagePrevious = this.callListPagePrevious.bind(this);
        this.callListByToken = this.callListByToken.bind(this);
        this.allTransactionsDialog = this.allTransactionsDialog.bind(this);
        this.sendCardBalance = this.sendCardBalance.bind(this);
        this.blockCard = this.blockCard.bind(this);
        this.stolenCard = this.stolenCard.bind(this);
        this.getStatusCardDetail = this.getStatusCardDetail.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTrans().then(resp => {
            const listTrans = resp.results;
            const previous = resp.links.previous;
            const next = resp.links.next;
            this.setState({
                listTrans,previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    callListByToken(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransByToken(this.state.cardToken).then(resp => {
            const listTrans = resp.data;
            const previous = '';
            const next = '';
            this.setState({
                listTrans,previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }



    callListPageNext(){
        this.callListPage(this.state.next)
    }

    callListPagePrevious(){
        this.callListPage(this.state.previous)
    }


    callListPage(link){
        console.log('callListPage')
        if (link.length>0 && ! link.includes('localhost')) {
            link = link.replace('http','https')
        }
        console.log(link)

        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransPage(link).then(resp => {
            const listTrans = resp.results;
            const previous = resp.links.previous;
            const next = resp.links.next;
            this.setState({
                listTrans,previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        this.getBalanceCardToken();
        this.getStatusCardDetail();
        this.setState({
            code:"",
            cardToken:"",
            operation:"payment",
            detailDialog: true,
            balance_tpp:0.0
        });
    }

    getBalanceCardToken(){
        this.usuarioService = new UsuarioService()
        let params = {card_token:this.state.dataTableSelectValue.card_token}
        this.usuarioService.postBalanceCardTokenTpp(params).then(resp => {
            if(resp.status === 'success'){
                const balance_tpp = resp.data.balance

                this.setState({
                    balance_tpp,
                    chargedYet: true});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    getStatusCardDetail(){
        const status_token_ewi = 'Without card';
        this.usuarioService = new UsuarioService();
        let params = {card_token: this.state.dataTableSelectValue.card_token}
        this.usuarioService.postTokenStatusCardDetail(params).then(resp => {
            if(resp.code === 200){
                const data = resp.data;
                const status_token_ewi = data.status_token_ewi == 1 ? 'BLOCK_CARD' : data.status_token_ewi == 2 ? 'STOLEN_CARD' : 'ACTIVE';
                this.setState({status_token_ewi});
            }else if(resp.code === 204){
                this.setState({status_token_ewi});
            }else {
                this.setState({status_token_ewi});
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            detailDialog: false,
        });
    }

    allTransactionsDialog(){
        let cardToken = this.state.dataTableSelectValue.card_token;
        this.setState({cardToken,
            detailDialog: false,
        });
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransByToken(cardToken).then(resp => {
            const listTrans = resp.data;
            const previous = '';
            const next = '';
            this.setState({
                listTrans,previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }
    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    blockCard(){
        let params = {
            card_token: this.state.dataTableSelectValue.card_token,
            status: STATUS_TOKEN_CHOICES.BLOCK_CARD
        }
        console.log("Block Card params: ", params);
        this.usuarioService = new UsuarioService()
        this.usuarioService.postTokenStatusCard(params).then(resp => {
            if (resp.code === 200) {
                this.setState({detailDialog: false});
                this.showSucccess("Block Card success!");
            } else if (resp.code === 204) {
                this.showError(`${resp.message}!`);
            } else {
                this.showError("Service error!");
            }
        }).catch(error => {
            console.error("postTokenStatusCard: ",error);
        });

    }
    stolenCard(){
        let params = {
            card_token: this.state.dataTableSelectValue.card_token,
            status: STATUS_TOKEN_CHOICES.STOLEN_CARD
        }
        console.log("Stolen Card params: ", params);
        this.usuarioService = new UsuarioService();
        this.usuarioService.postTokenStatusCard(params).then(resp => {
            if (resp.code === 200) {
                this.setState({detailDialog: false});
                this.showSucccess("Block Card success!");
            } else if (resp.code === 204) {
                this.showError(`${resp.message}!`);
            } else {
                this.showError("Service error!");
            }
        }).catch(error => {
            console.error("postTokenStatusCard: ",error);
        });
    }

    sendCardBalance() {
        this.props.transactionSelected(this.state.dataTableSelectValue);
    }

    render() {

        const {
            cardToken,
        } = this.state;

        const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;

        const refundFooter = (
            <React.Fragment>
                <Button label="Block card" className="p-button-warning" onClick={this.blockCard} />
                <Button label="Stolen card" className="p-button-danger" onClick={this.stolenCard} />
                <Button label="All Transactions" className="p-button-text" onClick={this.allTransactionsDialog} />
                <Button label="Balance" icon="pi pi-directions" className="p-button-info"
                        onClick={this.sendCardBalance} />
                <Button label="Close" icon="pi pi-times" className="p-button-secondary" onClick={this.hideDialog} />
            </React.Fragment>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Transactions</CardTitle>
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>}
                        </div>
                    </div>

                    <Toolbar className="p-mb-4 mb-10">
                        <label htmlFor="cardToken">Card Token</label>
                        <InputText id="cardToken" name="cardToken" type='number' value={cardToken} onChange={this.handleChange}/>
                        <Button label="Search" onClick={this.callListByToken} />
                    </Toolbar>

                    <Toolbar className="p-mb-4 mb-10">
                        <Button label="Reset" onClick={this.callListPrincipal} />
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="<<" onClick={this.callListPagePrevious} />
                        <Button label=">>" onClick={this.callListPageNext}/>
                    </Toolbar>

                    <DataTable value={this.state.listTrans}
                               reorderableColumns={true}
                               selectionMode="single" paginator={true} rows={30}
                               resizableColumns={true}
                               selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="single" style={{width:'3em'}}/>
                        <Column field="created" header="Created" sortable={true} className="ta-right"/>
                        <Column field="transaction_identifier" header="ID" sortable={true} className="ta-right"/>
                        <Column field="card_token" header="Card Token" sortable={true}/>
                        <Column field="merchant_name" header="Merchant Name" sortable={true} className="ta-right"/>
                        <Column field="amount" header="Amount" sortable={true} className="ta-right"/>
                        <Column field="merchant_country" header="Country" sortable={true} className="ta-right"/>
                        <Column field="transaction_type" header="Type" sortable={true} className="ta-right"/>
                        <Column field="requested_currency" header="Currency" sortable={true} className="ta-right"/>
                        <Column field="authorization_code" header="Authorization Code" sortable={true} className="ta-right"/>
                        <Column field="program_id" header="Program" sortable={true} className="ta-right"/>
                        <Column field="last4_pan" header="Last 4 digits" sortable={true} className="ta-right"/>
                        <Column field="response" header="Response" sortable={true} className="ta-right"/>
                        <Column field="merchant_category_code" header="Category Name" sortable={true} className="ta-right"/>


                    </DataTable>

                    <Toolbar className="p-mb-4 mb-10">
                        <Button label="Reset" onClick={this.callListPrincipal} />
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="<<" onClick={this.callListPagePrevious} />
                        <Button label=">>" onClick={this.callListPageNext}/>
                    </Toolbar>




                    <Dialog visible={this.state.detailDialog} style={{width: '920px', height: '270px'}}
                            header="Detail Transaction" modal className="p-fluid" footer={refundFooter}
                            onHide={this.hideDialog}>
                        <div className="datail-transaction">
                            <p>Transaction Id:        </p> <span>{this.state.dataTableSelectValue.transaction_identifier}</span>
                            <p>Program:               </p> <span>{this.state.dataTableSelectValue.program_id}</span>
                            <p>Transaction Date:      </p> <span>{this.state.dataTableSelectValue.date_created}</span>
                            <br/>
                            <p>Last 4 digits of Card: </p> <span>{this.state.dataTableSelectValue.last4_pan}</span>
                            <p>Amount:                </p> <span>{this.state.dataTableSelectValue.amount}</span>
                            <p>Transaction Country:   </p> <span>{this.state.dataTableSelectValue.merchant_country}</span>
                            <br/>
                            <p>Transaction Type:      </p> <span>{this.state.dataTableSelectValue.transaction_type}</span>
                            <p>Currency:              </p> <span>{this.state.dataTableSelectValue.requested_currency}</span>
                            <p>Authorization Code:    </p> <span>{this.state.dataTableSelectValue.authorization_code}</span>
                            <br/>
                            <p>No:                    </p> <span>{this.state.dataTableSelectValue.card_token}</span>
                            <p>Response:              </p> <span>{this.state.dataTableSelectValue.response}</span>
                        <p>Response Reason:              </p> <span>{this.state.dataTableSelectValue.response_reason}</span>
                            <br/>
                            <p>Email:                 </p> <span>{this.state.dataTableSelectValue.email}</span>
                            <p>Merchant Name:         </p> <span>{this.state.dataTableSelectValue.merchant_name}</span>
                            <hr/>
                            <p>Card token:         </p> <span>{this.state.status_token_ewi}</span>
                            <p>Card Balance:  <b>{this.state.balance_tpp}</b></p>

                        </div>
                    </Dialog>



                </Card>
            </div>
        );
    }
}

export default Transactions;
